import React from "react"
import { graphql } from "gatsby"
import Gallery from "react-grid-gallery"
import Layout from "../components/layout"
import Content, { HTMLContent } from "../components/content"

export const GenericPageTemplate = ({
  content,
  title,
  images,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  const pictures = images.filter(i=>i).map(i => {
    return { src: i.image || i, thumbnail: i.image || i }
  })

  return (
    <div>
      <h1 className="welcome center">{title}</h1>
      <PageContent className="content mt-5 mb-5" content={content} />
      <div style={{ overflow: "auto", maxWidth: "80%", margin: "0 auto" }}>
        <Gallery images={pictures} enableImageSelection={false} backdropClosesModal={true}/>
      </div>
    </div>
  )
}

export const GenericPage = ({ data }) => {
  const { markdownRemark: info } = data
  return (
    <Layout>
      <GenericPageTemplate
        title={info.frontmatter.title}
        content={info.html}
        images={info.frontmatter.galleryImages ?? []}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export default GenericPage

export const query = graphql`
  query GenericPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        galleryImages {
          image
        }
      }
    }
  }
`
